import { createRef, useEffect, useState } from 'react';
import alarm_sound from '../../../resources/alarm_sound.mp3';
import { socket } from '../../../socket';
import { SocketEvents } from 'newyear_shared';
import type { AlarmState } from 'newyear_shared/types/tv_alarm';

import './TvAlarm.scss';

export default function TVAlarm() {
    const audioRef = createRef<HTMLAudioElement>();
    const [state, setState] = useState<AlarmState>();

    useEffect(() => {
        socket.on(SocketEvents.alarmStateChange, onAlarmStateChange);
        
        return () => {
            socket.off(SocketEvents.alarmStateChange, onAlarmStateChange);
        };
    });

    function onAlarmStateChange(state: AlarmState) {
        if (audioRef.current) audioRef.current.currentTime = 0;
        state.isAlarming ? audioRef.current?.play() : audioRef.current?.pause();
        setState(state);
    }

    return (
        <div className={["tvalarm", state?.isAlarming ? 'active' : ''].join(' ')}>
            <audio ref={audioRef} src={alarm_sound} autoPlay={state?.isAlarming ?? false} loop={true}></audio>
        </div>
    );
}