import { useEffect, useState } from 'react';
import { AlarmPlayerObject } from 'newyear_shared/types/tv_alarm';
import { socket } from '../../../socket';
import { SocketEvents } from 'newyear_shared';

import './TvAlarmPlayers.scss';

export default function TVAlarmPlayers() {
    const [players, setPlayers] = useState<AlarmPlayerObject[]>([]);

    useEffect(() => {
        socket.on(SocketEvents.alarmPlayers, onPlayers);
        
        return () => {
            socket.off(SocketEvents.alarmPlayers, onPlayers);
        };
    });

    function onPlayers(players: AlarmPlayerObject[]) {
        setPlayers(players);
    }

    if (players.length <= 0) return <></>;
    return (
        <div className="tv_alarm_players">
            <p id="title">Players</p>
            <div className="players">{players.map((player, i) => <AlarmPlayer key={i} index={i} player={player} /> )}</div>
        </div>
    );
}

function AlarmPlayer(props: {
    index: number
    player: AlarmPlayerObject
}) {
    return (
        <div className="player">
            <div className="user">
                <p id='place' className={props.player.reacted ? 'reacted' : ''}>#{props.index+1}</p>
                <p id="name">{props.player.name}</p>
            </div>
            <p id="score">{props.player.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</p>
        </div>
    );
}