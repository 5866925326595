export enum SocketEvents {
    tvSateChange = 'tv state change',
    bingoReset = 'bingo reset',
    bingoStateChange = 'bingo state change',
    bingoSkip = 'bingo skip',
    bingoTracks = 'bingo tracks',
    alarmIntervalChange = 'alarm interval change',
    alarmStateChange = 'alarm state change',
    alarmReset = 'alarm reset',
    alarmRestart = 'alarm restart',
    alarmToggle = 'alarm toggle',
    alarmPlayers = 'alarm players',
    alarmPlayerJoin = 'alarm player join',
    alarmPlayerInfo = 'alarm player',
    alarmPlayerReact = 'alarm player react',
    plannerUpdate = 'planner update',
    plannerDetailedUpdate = 'planner detailed update',
}