import Timer from "../../widgets/Timer";
import { FaChessBoard } from 'react-icons/fa6';

import './home.scss'
import AppWidget from "./widget/app_widget";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
    const clicks = useRef<number[]>([]);

    const navigate = useNavigate();

    return (
        <div className="home">
            <div onClick={() => {
                const now = new Date().getTime();
                if (now - clicks.current[0] > 2_000)
                    clicks.current = [];
                clicks.current.push(now);
                
                if (clicks.current.length >= 5) {
                    navigate('/controller');
                    clicks.current.push(now);
                }
            }}>
                <Timer
                    seekingTime={new Date(2024, 11, 31, 23, 59, 59).getTime()}
                    prefix="2025 starter om"
                />
            </div>
            <div className="content">
                <p className="head">Nytår apps</p>
                <div className="app_list">
                    <AppWidget
                        title="Musik Bingo"
                        icon={FaChessBoard}
                        path="/bingo"
                    />
                </div>
            </div>
        </div>
    );
}