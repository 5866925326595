import { useEffect, useRef, useState } from "react";
import { socket } from "../../socket";
import { SocketEvents } from "newyear_shared";
import { useParams } from "react-router-dom";
import type { AlarmPlayerObject, AlarmState } from 'newyear_shared/types/tv_alarm';
import { FaFireExtinguisher } from "react-icons/fa6";

import './tv_alarm.scss';

export default function TVAlarmPlayer() {
    const loaded = useRef<boolean>(false);
    const [player, setPlayer] = useState<AlarmPlayerObject>();
    const [state, setState] = useState<AlarmState>();

    const params = useParams();

    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            onPlayerReconnect();
        }

        socket.on(SocketEvents.alarmPlayerInfo, onPlayerInfo);
        socket.on(SocketEvents.alarmPlayerJoin, onPlayerReconnect);
        socket.on(SocketEvents.alarmStateChange, onAlarmState);
        
        return () => {
            socket.off(SocketEvents.alarmPlayerInfo, onPlayerInfo);
            socket.off(SocketEvents.alarmPlayerJoin, onPlayerReconnect);
            socket.off(SocketEvents.alarmStateChange, onAlarmState);
        };
    });

    function onPlayerReconnect() {
        socket.emit(SocketEvents.alarmPlayerJoin, params['hash']);
    }

    function onPlayerInfo(player: AlarmPlayerObject) {
        setPlayer(player);
    }

    function onAlarmState(state: AlarmState) {
        setState(state);
    }

    if (!player) return < ></>;
    return (
        <div className="tv_alarm_player">
            <h1>{player.name}</h1>
            <h3>{player.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} point</h3>
            <div
                className={["btn", !(state?.active ?? false) ? 'disabled' : '', player.reacted ? 'reacted' : ''].join(' ')}
                onClick={() => {
                    if (!(state?.active ?? false)) return;
                    socket.emit(SocketEvents.alarmPlayerReact);
                }}
            >
                <FaFireExtinguisher id="icon" />
            </div>
        </div>
    );
}