import { useEffect, useState } from 'react';
import './planner.scss';
import { DetailedPlanEvent } from 'newyear_shared/types/planner';
import { socket } from '../../socket';
import { SocketEvents } from 'newyear_shared';
import { TVStates } from 'newyear_shared';
import { FaTrashAlt } from 'react-icons/fa';

export default function Planner() {
    const [plans, setPlans] = useState<DetailedPlanEvent[]>([]);

    useEffect(() => {
        socket.on(SocketEvents.plannerDetailedUpdate, onPlannerUpdate);
        
        return () => {
            socket.off(SocketEvents.plannerDetailedUpdate, onPlannerUpdate);
        }
    });

    function onPlannerUpdate(plans: DetailedPlanEvent[]) {
        setPlans(plans.sort((a, b) => (a.hour * 60 + a.minut + (a.hour < 15 ? 1440: 0)) - (b.hour * 60 + b.minut + (b.hour < 15 ? 1440: 0))));
    }

    function doPlannerUpdate(plans: DetailedPlanEvent) {
        socket.emit(SocketEvents.plannerDetailedUpdate, plans);
    }

    return (
        <div className="planner">
            <h1>Planner</h1>
            <button onClick={() => doPlannerUpdate({ hour: 15, minut: 15, title: '', action: null })}>Add event</button>
            <div className="events">{plans.map((event, i) =>
                <PlanEvent key={i} event={event} doUpdate={doPlannerUpdate} />
            )}</div>
        </div>
    );
}

function PlanEvent(props: {
    event: DetailedPlanEvent
    doUpdate: (plans: DetailedPlanEvent) => void
}) {
    function update(hour: number, minut: number, title: string, action: TVStates | null) {
        const event = props.event;
        event.hour = hour;
        event.minut = minut;
        event.title = title;
        event.action = action;
        props.doUpdate(event);
    }

    return (
        <div className="event">
            <select name="hour" id="hour" value={props.event.hour} onChange={e => update(parseInt(e.currentTarget.value), props.event.minut, props.event.title, props.event.action)}>{new Array(24).fill(0).map((_, i) =>
                <option key={i} value={i}>{i.toString().padStart(2, '0')}</option>
            )}</select>
            <select name="hour" id="hour" value={props.event.minut} onChange={e => update(props.event.hour, parseInt(e.currentTarget.value), props.event.title, props.event.action)}>{new Array(60/5).fill(0).map((_, i) =>
                <option key={i} value={i*5}>{(i*5).toString().padStart(2, '0')}</option>
            )}</select>
            <input type="text" name='title' id='title' value={props.event.title} onChange={e => update(props.event.hour, props.event.minut, e.currentTarget.value, props.event.action)} />
            <select name="action" id="action" value={props.event.action ?? ''} onChange={e => update(props.event.hour, props.event.minut, props.event.title, e.currentTarget.value.length <= 0 ? null : (e.currentTarget.value as TVStates))}>
                <option value="">N/A</option>
                {Object.keys(TVStates).map((key, i) =>
                    <option key={i} value={key}>{key}</option>
                )}
            </select>
            <FaTrashAlt onClick={() => update(-1, -1, '', null)} />
        </div>
    );
}