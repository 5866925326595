import { useEffect, useRef, useState } from 'react';
import type {PlanEvent} from 'newyear_shared/types/planner';
import './plan.scss';
import { socket } from '../../../socket';
import { SocketEvents } from 'newyear_shared';

export default function Plan() {
    const interval = useRef<NodeJS.Timer>();
    const [cursorTop, setCursorTop] = useState<number>(0);
    const [plan, setPlan] = useState<PlanEvent[]>([]);

    useEffect(() => {
        interval.current = setInterval(update, 1);

        socket.on(SocketEvents.plannerUpdate, onPlannerUpdate);
        
        return () => {
            socket.off(SocketEvents.plannerUpdate, onPlannerUpdate);
            clearInterval(interval.current);
        };
    });

    function onPlannerUpdate(plans: PlanEvent[]) {
        setPlan(plans.map(e => ({...e, time: new Date(e.time)})));
    }

    const eventElHeight = 35;
    const eventElSpace = 15;
    function update() {
        const now = new Date();

        const activeEventIndex = plan.findIndex(e => isEventActive(e));
        if (activeEventIndex < 0) {
            setCursorTop(-1);
            return;
        }

        const activeEvent = plan[activeEventIndex];
        const to = eventToDate(activeEvent);
        if (!to) {
            setCursorTop(eventElHeight * activeEventIndex + (eventElSpace * activeEventIndex) + (eventElHeight / 2));
            return;
        }

        const spand = to.getTime() - activeEvent.time.getTime();
        const left = to.getTime() - now.getTime();

        setCursorTop(eventElHeight * activeEventIndex + (eventElSpace * activeEventIndex) + (eventElHeight - eventElHeight * (left / spand)));
    }

    function eventToDate(event: PlanEvent) {
        for (let i = 0; i < plan.length; i++) {
            const e = plan[i];
            if (e.time !== event.time) continue;
            return plan.length > (i+1) ? plan[i+1].time : null;
        }
        return null;
    }

    function isEventActive(event: PlanEvent) {
        const now = new Date();
        const to = eventToDate(event);
        return (event.time < now && (!to || to > now));
    }

    return (
        <div className="plan">
            <div className="time_teller">
                <div className={["teller", cursorTop < 0 ? 'hidden' : ''].join(' ')} style={{top: cursorTop}}></div>
            </div>
            <div className="events">{
                plan.map((e, i) => <Event key={i} title={e.title} time={e.time} active={isEventActive(e)} />)
            }</div>
        </div>
    );
}

function Event(props: {
    title: string
    time: Date
    active?: boolean
}) {
    function formatTime(date: Date) {
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    return (
        <div className={["event", props.active ? 'active' : null].filter(e => !!e).join(' ')}>
            <p id="title">{props.title}</p>
            <p id="time">{formatTime(props.time)}</p>
        </div>
    );
}