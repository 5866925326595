import { createRef, useEffect, useState } from "react";
import { socket } from "../../socket";
import * as FA from 'react-icons/fa';
import * as MD from 'react-icons/md';
import { SocketEvents, TVStates } from "newyear_shared";
import type { BingoState } from 'newyear_shared/types/bingo';
import { AlarmState } from "newyear_shared/types/tv_alarm";

import './controller.scss';

export default function Controller() {
    const [state, setState] = useState<TVStates>(TVStates.music);
    const [cursorLeft, setCursorLeft] = useState<number>(0);

    useEffect(() => {
        socket.on(SocketEvents.tvSateChange, onStateChange);

        return () => {
            socket.off(SocketEvents.tvSateChange, onStateChange);
        }
    });

    function onStateChange(state: TVStates) {
        setState(state);
    }

    return (
        <div className="controller">
            <h1>Controls</h1>
            <div className="btn_list">
                <SourceButton
                    img_src="https://play-lh.googleusercontent.com/ob7jPvD-CrPNq8ZlCFRsJ3w3u3_uhJUZW0BL8JCaCIl-ARauuZhbbyK74PERSjdq5tQ=w240-h480-rw"
                    state={TVStates.dr}
                    current_state={state}
                    set_cursor={setCursorLeft}
                />
                <SourceButton
                    img_src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Spotify.png/1024px-Spotify.png"
                    state={TVStates.music}
                    current_state={state}
                    set_cursor={setCursorLeft}
                />
                <SourceButton
                    img_src="https://nyctrivialeague.com/wp-content/uploads/2017/11/Bingo-Icon.png"
                    state={TVStates.bingo}
                    current_state={state}
                    set_cursor={setCursorLeft}
                />
                <div className="cursor" style={{left: cursorLeft}}></div>
            </div>
            <BingoControls active={state === TVStates.bingo} />
            <TVAlarm />
        </div>
    );
}

function SourceButton(props: {
    img_src: string
    state: TVStates
    current_state: TVStates
    set_cursor: (left: number) => void
}) {
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        if (props.state === props.current_state) {
            if (!ref.current) return;
            props.set_cursor(ref.current.offsetLeft + ref.current.offsetWidth / 2);
        }
    });

    return (
        <div ref={ref} className={["btn", `state-${props.state}`].join(' ')} onClick={() => socket.emit(SocketEvents.tvSateChange, props.state)}>
            <img src={props.img_src} alt="cover" id="cover" />
        </div>
    );
}

function BingoControls(props: {
    active: boolean
}) {
    const [playing, setPlaying] = useState<boolean>(false);
    const [trackCount, setTrackCount] = useState<number>(0);
    const [playedCount, setPlayedCount] = useState<number>(0);

    useEffect(() => {
        socket.on(SocketEvents.bingoStateChange, onBingoStateChange);

        return () => {
            socket.off(SocketEvents.bingoStateChange, onBingoStateChange);
        };
    });

    function onBingoStateChange(state: BingoState) {
        setPlaying(state.isPlaying);
        setTrackCount(state.tracksCount);
        setPlayedCount(state.playedCount);
    }

    return (
        <div className="bingo_controls" {...{active: props.active ? 'active' : undefined}}>
            <h3>Bingo</h3>
            <div className="view">
                <MD.MdRestartAlt className="btn" onClick={() => {
                    if (window.confirm('Reset bingo?'))
                        socket.emit(SocketEvents.bingoReset);
                }} />
                <div className="count">
                    <p id="played">{(playedCount ?? '??').toString().padStart(2, '0')}</p>
                    <p id="amount">{(trackCount ?? '??').toString().padStart(2, '0')}</p>
                </div>
                {
                    playing
                        ? <FA.FaPause onClick={() => socket.emit(SocketEvents.bingoStateChange, false)} className="btn" id="play" />
                        : <FA.FaPlay onClick={() => socket.emit(SocketEvents.bingoStateChange, true)} className="btn" id="play" />
                }
                <FA.FaFastForward onClick={() => socket.emit(SocketEvents.bingoSkip)} className="btn" id="skip" />
            </div>
        </div>
    );
}

function TVAlarm() {
    const [state, setState] = useState<AlarmState>();
    const [from, setFrom] = useState<number>(0);
    const [to, setTo] = useState<number>(0);
    
    useEffect(() => {
        socket.on(SocketEvents.alarmStateChange, onAlarmStateChange);
        socket.on(SocketEvents.alarmIntervalChange, onIntervalChange);
        
        return () => {
            socket.off(SocketEvents.alarmStateChange, onAlarmStateChange);
            socket.off(SocketEvents.alarmIntervalChange, onIntervalChange);
        };
    });

    function onAlarmStateChange(state: AlarmState) {
        setState(state);
    }

    function onIntervalChange(from: number, to: number) {
        setFrom(from);
        setTo(to);
    }

    function doChangeInterval(from: number, to: number) {
        socket.emit(SocketEvents.alarmIntervalChange, from, to);
    }

    return (
        <div className="tv_alarm">
            <h3>TV Alarm</h3>
            <div className="view">
                {(state?.active ?? false)
                    ? <FA.FaToggleOn id="toggle" onClick={() => socket.emit(SocketEvents.alarmToggle, false)} />
                    : <FA.FaToggleOff id="toggle" onClick={() => socket.emit(SocketEvents.alarmToggle, true)} />
                }
                <MD.MdOutlineTimer onClick={() => socket.emit(SocketEvents.alarmRestart)} />
                <MD.MdRestartAlt onClick={() => {
                    if (window.confirm('Reset player scores?'))
                        socket.emit(SocketEvents.alarmReset);
                }} />
            </div>
            <div className="interval">
                <p>Interval i minutter</p>
                <input type="number" name="from" id="from" value={from} onChange={e => doChangeInterval(parseInt(e.currentTarget.value), to)} />
                -
                <input type="number" name="to" id="to" value={to} onChange={e => doChangeInterval(from, parseInt(e.currentTarget.value))} />
            </div>
        </div>
    );
}