import { SpotifyTrack } from 'newyear_shared/types/spotify';
import './BingoScene.scss';
import { useEffect, useState } from 'react';
import { socket } from '../../../socket';
import { SocketEvents } from 'newyear_shared';

export default function BingoScene(props: React.HTMLAttributes<HTMLDivElement>) {
    const [tracks, setTracks] = useState<SpotifyTrack[]>([]);

    useEffect(() => {
        socket.on(SocketEvents.bingoTracks, onBingoList);

        return () => {
            socket.off(SocketEvents.bingoTracks, onBingoList);
        };
    });

    function onBingoList(tracks: SpotifyTrack[]) {
        setTracks(tracks);
    }

    return (
        <div {...props} className={["bingo", props.className].filter(e => !!e).join(' ')}>
            <p id='title'>Afspillede sange</p>
            <div className="played_tracks">{
                tracks.slice().reverse().map((track, i) => 
                    <BingoTrack track={track} index={tracks.length - i - 1} />
                )
            }</div>
        </div>
    );
}

function BingoTrack(props: {
    index: number,
    track: SpotifyTrack,
}) {
    return (
        <div className="track">
            <p id="num">#{(props.index+1).toString().padStart(2, '0')}</p>
            <img id="cover" src={props.track.album.images[0].url} alt="cover" />
            <p id="title">{props.track.name}</p>
        </div>
    );
}