import { Route, Routes } from 'react-router-dom';
import MusicBingo from './pages/music_bingo/music_bingo';
import Home from './pages/home/home';
import TV from './pages/tv/tv';
import Controller from './pages/controller/controller';
import TVAlarmPlayer from './pages/tv_alarm/tv_alarm';

import './App.scss';
import Planner from './pages/planner/planner';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/bingo' element={<MusicBingo />} />
                <Route path='/player/:hash' element={<TVAlarmPlayer />} />
                <Route path='/tv' element={<TV />} />
                <Route path='/controller' element={<Controller />} />
                <Route path='/planner' element={<Planner />} />
            </Routes>
        </div>
    );
}

export default App;
